<!--
 * @Author: lbh
 * @Date: 2022-12-15 16:29:07
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-12 16:17:09
 * @Description: file content
-->
<template>
  <div
    class="member_system_graphic_text_box px-member_system_graphic_text_box"
    :class="`edit_${Xindex}_box `"
    @click="clickItem('box')"
  >
    <div class="msgtb_b">
      <div
        class="title"
        v-html="$util.rex.getHtml(configs[`${nowL}title`] || configs.title)"
      ></div>
      <div
        class="sub-title"
        v-html="$util.rex.getHtml(configs[`${nowL}subTitle`] || configs.subTitle)"
      ></div>
      <div class="b-mask">
        <div class="b-box">

          <div class="g-mask">
            <div class="w-mask">
              <div
                class="f-mask"
                :style="`background-color:${getRgba(configs.centerColor,0.1)}`"
              >
                <div
                  class="center-box"
                  :style="`background-color:${configs.centerColor}`"
                >
                  <img :src="configs.centerIcon" />
                  <div
                    class="text"
                    v-html="$util.rex.getHtml(configs[`${nowL}centerTitle`] || configs.centerTitle)"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <!-- items -->
          <div
            v-for="(item,index) in configs.items"
            :key="index"
            class="items"
            :style="showRadius ? `transform: rotate(${index * (360 / configs.items.length)}deg);` : ``"
          >
            <div
              class="i-m-b"
              :style="showRadius ? `transform: rotate(-${index * (360 / configs.items.length)}deg);` : ``"
            >
              <div
                class="i-mask"
                :style="`background-color:${getRgba(item.bgColor[0],0.06)}`"
              >
                <div
                  class="i-mask_2"
                  :style="`background-color:${getRgba(item.bgColor[0],.1)}`"
                >
                  <div
                    class="item"
                    :data-a="item.bgColor"
                    :style="`background-image: linear-gradient(180deg,${item.bgColor[0]} 0,${item.bgColor[1]} 100%);`"
                  >
                    <img :src="item.icon" />
                  </div>
                </div>
              </div>

              <div
                class="text"
                :style="`color:${item.bgColor[0]}`"
                v-html="$util.rex.getHtml(item[`${nowL}text`] || item.text)"
              ></div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import langMixin from '../../mixins/lang';
export default {
  mixins: [langMixin],
  props: {
    configs: {
      default () {
        return {
          bgColor: "",
          itemPadding: "0",
          items: [],
          subTitle: "",
          title: "",
          type: '',
        };
      },
    },
    Xindex: {
      default () {
        return '';
      },
    },
  },
  data () {
    return {
      isEdit: false,
      showRadius: false
    }
  },
  created () {
    this.isEdit = this.$store.getters.getIsEdit;
    setTimeout(() => {
      this.showRadius = true;
    }, 500);
  },
  methods: {
    clickItem (e, x, t) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x, t);
      }
    },
    getRgba (c, o) {
      if (c) {
        c = c.replace('rgb(', '')
        c = c.replace('rgba(', '')
        c = c.replace(')', '')
        let c_ = c.split(',');
        return `rgba(${c_[0]},${c_[1]},${c_[2]},${o})`
      }
      return '';
    }
  }
}
</script>

<style lang="less" scoped>
@media screen and(min-width:500px) {
  .member_system_graphic_text_box {
    padding: 60px 0;
    .msgtb_b {
      .title {
        font-size: 30px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        text-align: center;
      }
      .sub-title {
        font-size: 20px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        margin-top: 6px;
        text-align: center;
      }
      .b-mask {
        width: 810px;
        height: 810px;
        background-image: linear-gradient(
          0deg,
          rgba(240, 247, 255, 0) 0,
          rgb(240, 247, 255) 40%,
          rgb(240, 247, 255) 80%,
          rgba(240, 247, 255, 0) 100%
        );
        border-radius: 50%;
        margin: 10px auto;
        display: flex;
        align-items: center;
        justify-content: center;
        .b-box {
          width: 700px;
          height: 700px;
          background-color: #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          .g-mask {
            width: 304px;
            height: 304px;
            background-image: linear-gradient(
              0deg,
              rgba(240, 247, 255, 0) 0,
              rgb(240, 247, 255) 50%,
              rgba(240, 247, 255, 0) 100%
            );
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            .w-mask {
              width: 260px;
              height: 260px;
              background-color: #fff;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              .f-mask {
                width: 218px;
                height: 218px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                .center-box {
                  width: 136px;
                  height: 136px;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                  img {
                    width: 47px;
                    margin-bottom: 6px;
                  }
                  .text {
                    font-size: 20px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #ffffff;
                    text-align: center;
                  }
                }
              }
            }
          }
          .items {
            position: absolute;
            height: 295px;
            top: 55px;
            transform-origin: bottom;
            transition: 0.5s;
            .i-m-b {
              transition: 0.5s;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              .i-mask {
                width: 116px;
                height: 116px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(0, 226, 181, 0.06);
                border-radius: 50%;
                .i-mask_2 {
                  width: 95px;
                  height: 95px;
                  display: flex;
                  align-items: center;
                  background-color: rgba(0, 226, 181, 0.1);
                  justify-content: center;
                  border-radius: 50%;
                  .item {
                    width: 79px;
                    height: 79px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                      width: 42px;
                    }
                  }
                }
              }
              .text {
                font-size: 20px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and(max-width:1000px) {
  .px-member_system_graphic_text_box {
    .title {
      font-size: 16px !important;
    }
    .sub-title {
      font-size: 12px !important;
    }
  }
}

@media screen and(max-width:500px) {
  .px-member_system_graphic_text_box {
    padding: 25px 10px;
    .msgtb_b {
      .title {
        font-size: 15px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        text-align: center;
      }
      .sub-title {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        text-align: center;
      }
      .b-mask {
        width: 100%;
        height: calc(100vw - 20px);
        background-image: linear-gradient(
          0deg,
          rgba(240, 247, 255, 0) 0,
          rgb(240, 247, 255) 40%,
          rgb(240, 247, 255) 80%,
          rgba(240, 247, 255, 0) 100%
        );
        border-radius: 50%;
        margin: 10px auto;
        display: flex;
        align-items: center;
        justify-content: center;
        .b-box {
          width: calc(100% - 50px);
          height: calc(100vw - 70px);
          background-color: #fff;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          .g-mask {
            width: 128px;
            height: 128px;
            background-image: linear-gradient(
              0deg,
              rgba(240, 247, 255, 0) 0,
              rgb(240, 247, 255) 50%,
              rgba(240, 247, 255, 0) 100%
            );
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            .w-mask {
              width: 108px;
              height: 108px;
              background-color: #fff;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              .f-mask {
                width: 88px;
                height: 88px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                .center-box {
                  width: 68px;
                  height: 68px;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  flex-direction: column;
                  img {
                    width: 20px;
                  }
                  .text {
                    font-size: 12px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #ffffff;
                    text-align: center;
                  }
                }
              }
            }
          }
          .items {
            position: absolute;
            height: calc((100vw - 120px) / 2);
            transform-origin: bottom;
            top: 25px;
            transition: 0.5s;
            .i-m-b {
              transition: 0.5s;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              .i-mask {
                width: 50px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(0, 226, 181, 0.06);
                border-radius: 50%;
                .i-mask_2 {
                  width: 42px;
                  height: 42px;
                  display: flex;
                  align-items: center;
                  background-color: rgba(0, 226, 181, 0.1);
                  justify-content: center;
                  border-radius: 50%;
                  .item {
                    width: 34px;
                    height: 34px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                      width: 17px;
                    }
                  }
                }
              }
              .text {
                font-size: 12px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}
</style>